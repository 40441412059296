import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Section } from 'src/components';


const useStyles = makeStyles(() => ({
  issueText: {
    whiteSpace: 'pre-wrap',
  },
  divider: {
    marginTop: '20px',
    marginBottom: '20px',
  },
}));

const IssuesList = memo(({ issues }) => {
  const classes = useStyles();

  return <Section title='Problemas reportados'>
    <Box pl={1}>{ !issues?.length ?
      <Typography variant='body1'><em>Sin reportes</em></Typography>
      : issues.map((issue, ind) => <Fragment key={ind}>
        <Box px={2}>
          <Typography component='p' className={classes.issueText}>
            { issue.observation }
          </Typography>
        </Box>
        { ind + 1 < issues.length ? <Divider variant="middle" className={classes.divider}/> : null }
      </Fragment>)
    }</Box>
  </Section>;
});

IssuesList.propTypes = {
  issues: PropTypes.array,
};


export {
  IssuesList,
};
