import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Page } from 'src/components';


// TODO: Se deja así mientras aún no haya homes de nada además de arq
const LandscapeHome = () => {
  const history = useHistory();
  useEffect(() => {
    history.replace('/app/landscape/load-project');
  }, [ history ]);

  return (
    <Page title="Paisajes">
      Trabajo en progreso...
    </Page>
  );
};


export { LandscapeHome };
