import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const accordionTransitionPropObject = { unmountOnExit: true };

const ViewshedForm = memo(({ setHighlightedFeat, errors, index, setForm, viewshed, observationPoints }) => {
  const { featId, finished, dataOnWeb } = viewshed;

  const stopEventPropagation = event => event.stopPropagation();

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      viewsheds: pf.viewsheds.map((v, currInd) => index === currInd ? ({ ...v, [fieldName]: data }) : v),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      viewsheds: pf.viewsheds.map((v, currInd) => index === currInd ? ({ ...v, [e.target.name]: e.target.checked }) : v),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      landscapeUnits: pf.landscapeUnits.map((lu, currInd) =>
        index === currInd ? ({ ...lu, [e.target.name]: parseNumber(lu[e.target.name], 5) ?? null }) : lu,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeBigNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 5, wholePart: 10 })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Cuenca visual #{index + 1}{`: ${viewshed.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={viewshed.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área [ha]</TypographicInputLabel>
          <TextField value={viewshed.areaHa ?? ''} name="areaHa" id={`${featId}-area-ha`} variant="outlined" size="small"
            onBlur={onBlurNumber} fullWidth autoComplete="off"
            error={Boolean(errors?.areaHa)}
            onChange={onChangeBigNumber}
            helperText={<>
              <span>Área en ha, con hasta 5 decimales.</span>{viewshed.areaHa !== null ?
                <span>Valor en m2: {viewshed.areaHa * 10000}</span> : null}
            </>}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <Box mb={1}>
            <TypographicInputLabel htmlFor={`${featId}-observation-points`}>
              Puntos de observación
            </TypographicInputLabel>
          </Box>
          <Box my={1}>
            <Autocomplete
              value={viewshed.observationPoints}
              multiple
              name={'observationPoints'}
              options={observationPoints.filter(op => op.finished).map(op => op.featId)}
              getOptionLabel={value => {
                const index = observationPoints.findIndex(op => op.featId === value);
                const op = observationPoints[index];
                return `#${index + 1}: ${op.name}`;
              }}
              noOptionsText="No hay puntos de observación disponibles"
              filterSelectedOptions
              renderInput={params => (
                <TextField
                  {...params}
                  id={`${featId}-observation-points`}
                  variant="outlined"
                  placeholder={viewshed.observationPoints.length > 0 ? '' : 'Puntos de observación'}
                  helperText={'Los puntos de observación deben estar marcados como finalizados para poder ser seleccionados'}
                />
              )}
              onChange={(event, newValue) => {
                onChange({ target: { name: 'observationPoints', value: newValue === null ? null : newValue } });
              }}
            />
          </Box>
        </Grid>

      </Grid>
    </AccordionDetails>
  </Accordion>;
});

ViewshedForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  viewshed: PropTypes.object,
  observationPoints: PropTypes.array,
};

ViewshedForm.displayName = 'ViewshedForm';


export { ViewshedForm };