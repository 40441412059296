import React from 'react';
import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { DangerButton } from 'src/components';


// TODO: ponerle también un loading
const ConfirmationDialog = props => {
  const { onConfirm, closeDialog, children, title, message, yesMessage, noMessage, disabled, errorMessage, dangerous } = props;

  const YesButton = dangerous ? DangerButton : Button;
  const confirmAndClose = async () => {
    await onConfirm();
    closeDialog();
  };

  return <>
    {title && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
    <DialogContent>
      <Box p={1}>
        { children ? children : <Typography variant="body1" color="textPrimary">{ message }</Typography> }
      </Box>
      { errorMessage &&
        <Alert severity='error'>{errorMessage}</Alert>
      }
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="secondary" disabled={disabled}>{ noMessage ?? 'Cancelar' }</Button>
      <YesButton variant="text" onClick={confirmAndClose} color="primary" disabled={disabled}>{ yesMessage ?? 'Confirmar' }</YesButton>
    </DialogActions>
  </>;
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
  yesMessage: PropTypes.string,
  noMessage: PropTypes.string,
  children: PropTypes.any,
  message: PropTypes.string,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  dangerous: PropTypes.bool,
};


export {
  ConfirmationDialog,
};
