import { /*, del*/ get, put } from 'src/services/api';


const saveForm = async data => await put('/landscape', data);
const getData = async seaProjectId => (await get(`/landscape/data/${seaProjectId}`)).data;


export {
  saveForm,
  getData,
};