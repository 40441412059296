import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, Typography } from '@material-ui/core';
import { ProjectYearSelection } from 'src/scenes/LoadProject/components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import Loading from 'src/components/Loading';
import { Page, Section, Project, ObservationsSection, ConfirmationDialog, DialogWrapper, DangerButton } from 'src/components';
import { PaWContents } from 'src/scenes/PartsAndWorks/components';
import { projectApi, partsAndWorksApi, optionsApi } from 'src/services';


const typeKey = 'parts-and-works';

const DialogContents = ({ type, actions, data }) => {
  switch (type) {
    case 'delete-data': {
      return <ConfirmationDialog
        onConfirm={actions.onConfirm}
        closeDialog={actions.closeDialog}
        yesMessage='Borrar datos'
        title='Borrar datos de partes y obras'
        message='Al confirmar, borrarás todos los datos de este proyecto. ¿Realmente desear borrar los datos?'
        errorMessage={data.errorMessage}
        disabled={data.disabled}
        dangerous
      />;
    }
    default:
      break;
  }
};

DialogContents.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.object,
  disabled: PropTypes.bool,
  actions: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: 200,
    maxWidth: 300,
  },
  submitButton: {
    display: 'block',
    marginTop: theme.spacing(5),
  },
  comments: {
    width: '100%',
  },
  success: {
    color: 'green',
  },
  hr: {
    marginLeft: 0,
    width: '25%',
  },
}));

const getEmptyPawForm = () => ({
  geoJson: {
    features: [],
    type: 'FeatureCollection',
  },
  errors: [],
  empty: true,
  problemsDescription: '',
  checkError: false,
});

const PartsAndWorksForm = () => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { seaProjectId } = match.params;

  const history = useHistory();
  const pushProjectToHistory = seaProject => history.push({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });
  const replaceProjectToHistory = seaProject => history.replace({
    pathname: `load-project/${seaProject.id}/form`,
    state: { seaProject: seaProject },
  });

  const { state: locationState } = useLocation();
  const [ seaProject, setSeaProject ] = useState(locationState?.seaProject);
  const [ sendingData, setSendingData ] = useState(false);

  const [ form, setForm ] = useState({
    pawComponents: getEmptyPawForm(),
    comments: '',
  });
  const [ loadingProject, setLoadingProject ] = useState(!seaProject);
  const [ loadedFormDataAndOptions, setLoadedFormDataAndOptions ] = useState(false);
  const [ phaseOptions, setPhaseOptions ] = useState([]);
  const [ temporalityOptions, setTemporalityOptions ] = useState([]);
  const [ projectYear, setProjectYear ] = useState(seaProject?.year ?? null);
  const [ randomProjectNotFound, setRandomProjectNotFound ] = useState(false);
  const [ currentStatus, setCurrentStatus ] = useState(null);
  const [ deletingData, setDeletingData ] = useState(false);
  const [ dialog, setDialog ] = useState({ isOpen: false, type: null, data: null, actions: null });

  const hasCurrentData = currentStatus?.id != null;
  const restartForm = useCallback(() => history.push('/app/'), [ history ]);

  const updatePawContent = useCallback(({ newData }) =>
    setForm(ps => ({
      ...ps,
      pawComponents: {
        ...ps.pawComponents,
        ...newData,
        geoJson: {
          type: 'FeatureCollection',
          features: [ ...ps.pawComponents.geoJson.features, ...newData.geoJson.features ],
        },
      },
    }))
  , []);

  const setProblemDescription = useCallback(newDescription => setForm(ps => ({
    ...ps,
    pawComponents: {
      ...ps.pawComponents,
      problemsDescription: newDescription,
    },
  })), []);

  const setCheckError = useCallback(checked => setForm(ps => ({
    ...ps,
    pawComponents: {
      ...ps.pawComponents,
      checkError: checked,
    },
  })), []);

  const deletePawContent = useCallback(() => setForm(ps => {
    console.log(ps);
    return ({
      ...ps,
      pawComponents: {
        ...getEmptyPawForm(),
        problemsDescription: ps.pawComponents.problemsDescription,
        checkError: ps.pawComponents.checkError,
      },
    });
  }), []);

  useEffect(() => {
    if (seaProjectId) {
      const fetchFormDataAndOptions = async () => {
        try {
          const [ phaseOptions, temporalityOptions, currentStatus ] = await Promise.all([
            optionsApi.getPhases({ component: 'paw' }),
            optionsApi.getTemporalities({ component: 'paw' }),
            partsAndWorksApi.getCurrentStatus(seaProjectId),
          ]);
          setCurrentStatus(currentStatus);
          if (currentStatus) {
            setForm(ps => ({
              ...ps,
              comments: currentStatus.comments,
              pawComponents: {
                ...ps.pawComponents,
                problemsDescription: currentStatus.geomProblemsDescription,
                checkError: Boolean(currentStatus.geomProblemsDescription),
              },
            }));
          }
          setTemporalityOptions(temporalityOptions);
          setPhaseOptions(phaseOptions);
          setLoadedFormDataAndOptions(true);
        } catch (e) {
          console.log(e);
          toast.error(e.serverMessage ?? 'Hubo un error al cargas los datos del formulario, por favor intenta más tarde');
        }
      };
      fetchFormDataAndOptions();
    } else {
      getPrioritizedProject();
    }
  // eslint-disable-next-line
  }, [ seaProjectId ]);

  useEffect(() => {
    if (seaProjectId && !seaProject) {
      const fetchData = async () => {
        setLoadingProject(true);
        const { seaProject } = await projectApi.getProjectBySeaId(seaProjectId);
        setSeaProject(seaProject);
        setLoadingProject(false);
      };
      fetchData();
    }
  }, [ seaProjectId, seaProject ]);

  const getPrioritizedProject = async () => {
    try {
      const result = (await projectApi.getPriorityProject({ type: 'parts-and-works' }))?.data;
      if (result) {
        const { seaProject } = result;
        setSeaProject(result.seaProject);
        if (!seaProject) {
          setLoadingProject(false);
        } else {
          setLoadingProject(false);
          replaceProjectToHistory(seaProject);
        }
      } else {
        setLoadingProject(false);
      }
    } catch (err) {
      setLoadingProject(false);
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
      restartForm();
    }
  };

  const getRandomizedProject = async year => {
    setLoadingProject(true);
    setRandomProjectNotFound(false);
    try {
      const result = (await projectApi.getRandomProject({ year, type: typeKey }))?.data;
      const { seaProject } = result || {};
      if (!seaProject) {
        setRandomProjectNotFound(true);
        setLoadingProject(false);
      } else {
        setSeaProject(seaProject);
        setLoadingProject(false);
        pushProjectToHistory(seaProject);
      }
    } catch (err) {
      if (err.serverMessage) {
        toast.error(err.serverMessage);
      }
      console.error(err);
      restartForm();
    }
  };

  const onChangeProjectYear = ({ projectYear }) => {
    setProjectYear(projectYear);
    getRandomizedProject(projectYear);
  };

  const onSubmitGenerator = () => async () => {
    if (sendingData) {
      return;
    }
    try {
      setSendingData(true);
      toast.info('Guardando la información');
      form.seaId = seaProjectId;
      const bodyToSend = {};
      bodyToSend.seaId = seaProjectId;
      bodyToSend.comments = form.comments;
      bodyToSend.geoJson = {
        type: form.pawComponents.geoJson.type,
        features: form.pawComponents.geoJson.features,
      };
      bodyToSend.geomProblemsDescription = form.pawComponents.checkError ? form.pawComponents.problemsDescription : '';
      const { message } = await partsAndWorksApi.saveForm(bodyToSend);
      toast.dismiss();
      toast.success(message);
      setSendingData(false);
      restartForm();
    } catch (e) {
      toast.dismiss();
      setSendingData(false);
      console.error(e);
      toast.error(e.serverMessage ?? e.serviceMessage ?? 'Ocurrió un error inesperado, por favor inténtalo más tarde');
    }
  };

  const onSubmit = onSubmitGenerator(false);
  const onCommentUpdate = comments => setForm(pf => ({ ...pf, comments }));

  const closeDialog = useCallback(() => setDialog({ isOpen: false, type: null, data: null, actions: null }), []);

  const openDeleteDialog = () => {
    setDialog({ isOpen: true, type: 'delete-data', actions: { closeDialog, onConfirm: deleteData }, data: { disabled: false } });
  };

  const deleteData = async () => {
    setDeletingData(true);
    setDialog(pd => ({ ...pd, data: { disabled: true } }));
    try {
      await partsAndWorksApi.deleteData(currentStatus.id);
      toast.success('Datos borrados. Puedes volver a llenar este formulario');
      setForm({
        pawComponents: getEmptyPawForm(),
        comments: '',
      });
      setCurrentStatus(null);
    } catch (e) {
      console.error(e);
      const errorMessage = e.serverMessage ?? e.serviceMessage ?? 'Hubo un error al borrar los datos, por favor intenta más tarde';
      setDialog(pd => ({ ...pd, data: { disabled: true, errorMessage } }));
      toast.error(errorMessage);
    } finally {
      setDeletingData(false);
    }
  };

  const formStatus = useMemo(() => currentStatus?.createdAt ?
    { message: 'Enviado', status: 'success', updatedAt: currentStatus.updatedAt } : { message: 'Pendiente', status: 'pending' }
  , [ currentStatus ]);

  const { pawComponents } = form;

  return loadingProject ? <Loading/> : <>
    {!seaProject && <>
      <ProjectYearSelection formType={typeKey} projectYear={projectYear} updateState={onChangeProjectYear} />
      { randomProjectNotFound && <Typography variant="body1">No se encontró proyecto para el año seleccionado</Typography> }
    </>}
    { seaProject && (!loadedFormDataAndOptions ? <Loading/> : <>
      <Section title="Proyecto">
        <Box>
          <Project project={seaProject} formStatus={formStatus} formType={typeKey}></Project>
        </Box>
      </Section>
      <Page title={`Partes y obras - ${seaProject.nombre}`}>
        <PaWContents
          phaseOptions={phaseOptions.map(phases => phases.value)}
          pawContent={pawComponents}
          updatePawContent={updatePawContent}
          temporalityOptions={temporalityOptions.map(temporality => temporality.value)}
          deletePawContent={deletePawContent}
          setProblemDescription={setProblemDescription}
          setCheckError={setCheckError}
        />
        <ObservationsSection
          onChange={ e => onCommentUpdate(e.target.value)} className={classes.comments} value={form.comments}
        />
        <Box display="flex" flex={2}>
          {hasCurrentData &&
            <Box mx={1.5}>
              <DangerButton className={classes.submitButton} type="button"
                disabled={deletingData} onClick={openDeleteDialog} >
                Borrar datos
              </DangerButton>
            </Box>
          }
          <Box>
            <Button className={classes.submitButton} type="button" variant="contained" color="primary" onClick={onSubmit}
              disabled={(currentStatus?.nPaw === 0 && pawComponents.empty && (!pawComponents.problemsDescription ||
                !pawComponents.checkError)) || sendingData}
            >
              Enviar
            </Button>
          </Box>
        </Box>
        <DialogWrapper maxWidth='sm' fullWidth onClose={closeDialog} open={dialog.isOpen}>
          { dialog.isOpen && <DialogContents type={dialog.type} actions={dialog.actions} data={dialog.data}/> }
        </DialogWrapper>
      </Page>
    </>)}
  </>;
};


export { PartsAndWorksForm };
