import React, { useState, useMemo } from 'react';
import { Grid, FormControlLabel, Checkbox, Typography, Box, TextField,
  List, ListItem, ListItemText, Divider } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import { Map, Section } from 'src/components';
import { filterGeomTypeFromGeoJson, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  locality: {
    width: '98%',
    marginTop: theme.spacing(2),
  },
  succes: {
    color: 'green',
  },
  errorList: {
    marginLeft: 20,
  },
}));

const nameKeys = [ 'nombre', 'nompyo', 'name' ];
const phaseKeys = [ 'fases', 'fasepyo', 'phases' ];
const temporalityKeys = [ 'temporalidad', 'tempo', 'temporality' ];

const getHelp = (tempOptions, phaseOptions) => <div>
  <p>
    El formulario buscará los siguientes elementos en los KML/Z subidos:
  </p>
  <ul>
    <li><strong>Nombre</strong> o <strong>NOM_PYO</strong> o <strong>name</strong>: Obligatorio, nombre de la parte u obra.</li>
    <li><strong>Fases</strong> o <strong>FASE_PYO</strong>: Obligatorio, debe ser uno de estos: { phaseOptions.join(', ') }</li>
    <li><strong>Temporalidad</strong> o <strong>TEMPO</strong>: Obligatorio, debe ser uno de estos: { tempOptions.join(', ') }</li>
    <li><strong>ÁREA_HA</strong>: Opcional.</li>
    <li><strong>SU_M2</strong> o <strong>ÁREA_M2</strong>: Opcional.</li>
    <li><strong>EXT_M</strong> o <strong>EXT</strong>: Opcional.</li>
  </ul>
  <p>
    Si los elementos de áreas y extensión no son dados, luego se calcularán automáticamente. Si se da solo un elemento de área,
    el otro se calculará en base a él. Los números se aproximarán luego a los 5 decimales.
  </p>
  <p>
    Para los nombres de los elementos, no se distingue entre mayúsculas o minúsculas, ni tampoco importará la presencia o ausencia de
    guiones bajos en los nombres, así por ejemplo funciona tanto {'"'}ÁREA_HA{'"'} como {'"'}areaha{'"'}.
  </p>
</div>;

const PaWContents = ({ phaseOptions, temporalityOptions, pawContent, updatePawContent, deletePawContent, setProblemDescription,
  setCheckError }) => {
  const { geoJson, errors, empty, problemsDescription, checkError } = pawContent;
  // eslint-disable-next-line react-hooks/exhaustive-deps -- este componente solo existe cuando las options ya están cargadas
  const infoContents = useMemo(() => getHelp(temporalityOptions, phaseOptions), []);

  const mapName = `paw-map`;
  const updateState = newData => updatePawContent({ newData });

  const [ listFiles, setListFiles ] = useState([]);
  const [ warning, setWarning ] = useState('');

  const deleteState = () => {
    setListFiles([]);
    deletePawContent();
    setWarning('');
  };

  const classes = useStyles();

  const handleGeoJson = ({ geoJson, kmlFileName }) => {
    let listErrors = [];
    let validStructure = true;

    if (listFiles.includes(kmlFileName)) {
      listErrors = [ { name: '', error: `Ya ha sido cargado un archivo con el nombre ${kmlFileName}.` } ];
      updateState({
        geoJson: {
          type: 'FeatureCollection',
          features: [],
        },
        errors: listErrors,
      });
      return;
    }

    const validTypes = [ 'Polygon', 'Point', 'MultiPolygon', 'MultiPoint', 'LineString', 'MultiLineString' ];
    // filtrar geometrías vacías
    const { geoJson: filteredGeoJson, changedObj } = filterGeomTypeFromGeoJson({ geoJson, validTypes });
    const filteredNulls = changedObj.null;
    if (!filteredGeoJson.features?.length) {
      return updateState({
        geoJson: null,
        errors: [ {
          name: '',
          error: `No se encontraron geometrías válidas en el KML cargado${filteredNulls ? ' (se filtraron geometrías vacías)' : ''}`,
        } ],
      });
    }
    const uniqueInList = (name, list) => list.filter(item => item === name).length === 1;

    const checkedData = [];
    let invalidFeatureName = '';

    for (let i = 0; i < filteredGeoJson.features.length; i++) {
      const feature = filteredGeoJson.features[i];
      const { properties } = feature;

      let nameToCheck;
      let phasesToCheck;
      let temporalityToCheck;
      let areaHaToCheck = null;
      let areaM2ToCheck = null;
      let extToCheck = null;
      Object.keys(properties).forEach(key => {
        const normalizedKey = key.toLowerCase().trim().replaceAll('á', 'a').replaceAll('_', '');
        if (nameKeys.includes(normalizedKey)) {
          nameToCheck = properties[key]?.toString()?.trim();
        } else if (phaseKeys.includes(normalizedKey)) {
          const tempPhases = properties[key].toString().split(',');
          phasesToCheck = tempPhases.map(phase => phase.trim().toLowerCase());
        } else if (temporalityKeys.includes(normalizedKey)) {
          temporalityToCheck = properties[key]?.toLowerCase()?.trim();
        } else if (normalizedKey === 'areaha') {
          areaHaToCheck = properties[key];
        } else if (normalizedKey === 'aream2' || normalizedKey === 'sum2') {
          areaM2ToCheck = properties[key];
        } else if (normalizedKey === 'extm' || normalizedKey === 'ext') {
          extToCheck = properties[key];
        }
      });

      if (!nameToCheck || !phasesToCheck || !temporalityToCheck) {
        validStructure = false;
        invalidFeatureName = nameToCheck;
        break;
      }

      const listPhases = [];
      phasesToCheck.forEach(phase => {
        if (!phaseOptions.includes(phase)) {
          listErrors.push({ name: nameToCheck, error: 'Valor fase inválido', value: phase });
        }
        if (!uniqueInList(phase, phasesToCheck) && !listPhases.includes(phase)) {
          listPhases.push(phase);
          listErrors.push({ name: nameToCheck, error: 'Valor fase repetido', value: phase });
        }
      });

      if (!temporalityOptions.includes(temporalityToCheck)) {
        listErrors.push({ name: nameToCheck, error: 'Valor temporalidad inválido', value: temporalityToCheck });
      }

      const numberPrecision = { wholePart: 10, decimals: 7 };
      const makeBadNumberMsg = name =>
        `Formato de ${name} inválido. Debe ser un número de a lo más ${numberPrecision.wholePart} dígitos de parte entera y ` +
        `hasta ${numberPrecision.decimals} decimales`;

      if (areaHaToCheck !== null) {
        areaHaToCheck = typeof areaHaToCheck === 'number' ? areaHaToCheck
          : areaHaToCheck?.toString()?.trim();
        if (!testDecimalsFormat(areaHaToCheck, numberPrecision)) {
          listErrors.push({ name: nameToCheck, value: areaHaToCheck, error: makeBadNumberMsg('área en hectáreas') });
        }
      }
      if (areaM2ToCheck !== null) {
        areaM2ToCheck = typeof areaM2ToCheck === 'number' ? areaM2ToCheck
          : areaM2ToCheck?.toString()?.trim();
        if (!testDecimalsFormat(areaM2ToCheck, numberPrecision)) {
          listErrors.push({ name: nameToCheck, value: areaM2ToCheck, error: makeBadNumberMsg('área en m2') });
        }
      }
      if (extToCheck !== null) {
        extToCheck = typeof extToCheck === 'number' ? extToCheck
          : extToCheck?.toString()?.trim();
        if (!testDecimalsFormat(extToCheck, numberPrecision)) {
          listErrors.push({ name: nameToCheck, value: extToCheck, error: makeBadNumberMsg('extensión en metros') });
        }
      }

      checkedData.push({
        ...feature,
        properties: {
          name: nameToCheck,
          phases: phasesToCheck.join(','),
          temporality: temporalityToCheck,
          areaHa: areaHaToCheck,
          areaM2: areaM2ToCheck,
          extM: extToCheck,
        },
      });
    }
    if (!validStructure) {
      listErrors = [ { name: '', error: invalidFeatureName ?
        `Error en estructura de los datos. El elemento ${invalidFeatureName} no posee campo "fases" y/o "valor"` :
        `Error en estructura de los datos. Alguno de los elementos no posee al menos uno de los campos: "nombre", "fases", "temporalidad"`,
      } ];
    }

    if (listErrors.length === 0) {
      setListFiles(ps => ([ ...ps, kmlFileName ]));
    }

    if (Object.keys(changedObj).length) {
      console.warn(changedObj);
      if (filteredNulls) {
        setWarning('Se encontraron y filtraron geometrías vacías. Considera revisar el KML si esto no te parece correcto');
      }
    } else {
      setWarning('');
    }

    updateState({
      geoJson: {
        type: 'FeatureCollection',
        features: listErrors.length === 0 ? checkedData : [],
      },
      // eslint-disable-next-line
      errors: listErrors,
      empty: false,
    });
    return;
  };

  return <Section title="Información cargada" infoContents={infoContents}>
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        { empty ?
          <Grid container direction="row" spacing={4}>
            <Grid item xs={12} sm container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6" component="span" color="textSecondary">
                  Esperando documento
                </Typography>
              </Grid>
            </Grid>
          </Grid> :
          <>
            { listFiles.length > 0 &&
              <>
                <Typography variant="h6" component="span" color="textSecondary">
                  Documentos:
                </Typography>
                <List>
                  {listFiles.map((filename, i) => (
                    <Box key={`filename-${i}`}>
                      <ListItem alignItems="flex-start">
                        - {filename}
                      </ListItem>
                    </Box>
                  ))}
                </List>
              </>
            }
            { errors.length > 0 ?
              <>
                <Typography variant="h6" component="span">
                  Errores detectados al cargar documento:
                </Typography>
                <List>
                  {errors.map((error, i) => (
                    <Box key={i}>
                      <ListItem alignItems="flex-start">
                        <Grid container direction="column">
                          {error.name ? (
                            <Grid item>
                              <Alert severity="error">{`Error encontrado en elemento: ${error.name}`}</Alert>
                            </Grid>
                          ) : (
                            <Grid item>
                              <Alert severity="error">{`Error al cargar archivo`}</Alert>
                            </Grid>
                          ) }
                          <Grid item>
                            <ListItemText className={classes.errorList}
                              primary={error.error || ''}
                              secondary={error.value || ''}
                            />
                          </Grid>
                        </Grid>
                      </ListItem>
                      {i < errors.length - 1 && <Divider component="li" />}
                    </Box>
                  ))}
                </List>
              </> :
              <Grid container direction="row" spacing={4}>
                <Grid item xs={12} sm container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h6" component="span" className={classes.succes}>
                        ¡Documento cargado con éxito!
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </>
        }
        { checkError &&
            <Grid item xs>
              <Box my={3}>
                <Box>
                  <TextField
                    className={classes.locality}
                    label="Descripción problemas en kml"
                    minRows={8}
                    maxRows={14}
                    multiline
                    name="descripcion-problemas"
                    onChange={e => setProblemDescription(e.target.value)}
                    placeholder="Describe los problemas que hay con las geometrías"
                    type="text"
                    value={problemsDescription}
                    variant="outlined"
                  />
                </Box>
              </Box>
            </Grid>
        }
      </Grid>
      <Grid item xs={6}>
        <Map nameId={mapName} geoJson={geoJson} handleGeoJson={handleGeoJson} importKml={true} deleteGeoJson={deleteState} />
        <FormControlLabel
          control={
            <Checkbox color="primary" checked={checkError}
              onChange={event => {
                setCheckError(event.target.checked);
              }}
            />
          }
          labelPlacement="start"
          label="¿Datos erróneos o no disponibles?"
        />
        { warning &&
          <Alert severity="warning">{warning}</Alert>
        }
      </Grid>
    </Grid>
  </Section>;
};

PaWContents.propTypes = {
  phaseOptions: PropTypes.array,
  temporalityOptions: PropTypes.array,
  pawContent: PropTypes.object,
  updatePawContent: PropTypes.func,
  deletePawContent: PropTypes.func,
  setProblemDescription: PropTypes.func,
  setCheckError: PropTypes.func,

};


export { PaWContents };
