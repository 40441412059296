import React, { useState } from 'react';

import PropTypes from 'prop-types';
import { Button, Box, DialogTitle, DialogContent, DialogActions, Typography, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';


const SelectLandscapeSection = props => {
  const { confirmFn, closeDialog } = props;

  const handleConfirm = () => {
    confirmFn(selected);
    closeDialog();
  };

  const [ selected, setSelected ] = useState(null);

  const sections = [
    { label: 'Unidades del paisaje', value: 'landscapeUnits' },
    { label: 'Cuenca visual', value: 'viewsheds' },
  ];

  const getLabels = value => value.label || '';
  const getOptionSelected = (option, value) => option.value === value.value;

  return <>
    <DialogTitle id="form-dialog-title">Seleccione tipo de formulario</DialogTitle>
    <DialogContent>
      <Box p={1}>
        <Typography variant="body1" color="textPrimary">
          Seleccione la sección a la cual seran agregados los poligonos dentro del kml.
        </Typography>
        <br />
        <Autocomplete
          value={selected}
          name={'section'}
          options={sections}
          getOptionLabel={getLabels}
          getOptionSelected={getOptionSelected}
          filterSelectedOptions
          renderInput={params => (
            <TextField
              {...params}
              id={`section`}
              variant="outlined"
              placeholder={'Seleccione formulario'}
            />
          )}
          onChange={(event, newValue) => setSelected(newValue)}
        />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={closeDialog} color="secondary">Cancelar</Button>
      <Button onClick={handleConfirm} color="primary">Confirmar</Button>
    </DialogActions>
  </>;
};

SelectLandscapeSection.propTypes = {
  confirmFn: PropTypes.func.isRequired,
  closeDialog: PropTypes.func.isRequired,
};


export {
  SelectLandscapeSection,
};
