import React, { memo, useCallback } from 'react';
import { Grid, Button, Typography, Box, Accordion, AccordionSummary, AccordionDetails, TextField,
  FormControlLabel, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import { Autocomplete } from '@material-ui/lab';

import * as commons from 'src/scenes/subformCommons/subformCommons';
import { TypographicInputLabel } from 'src/components';
import { parseNumber, testDecimalsFormat } from 'src/utils';


const useStyles = makeStyles(theme => ({
  ...commons.styles(theme),
}));

const getOptionSelected = (option, value) => option.value === value || option === value;

const accordionTransitionPropObject = { unmountOnExit: true };

const LandscapeUnitForm = memo(({ setHighlightedFeat, errors, index, setForm, landscapeUnit, landscapeOptions }) => {
  const { featId, finished, dataOnWeb } = landscapeUnit;

  const stopEventPropagation = event => event.stopPropagation();

  const getLandscapeCategoryLabels = useCallback(value => landscapeOptions.labels[value?.value ?? value], [ landscapeOptions ]);

  const toggleHighlight = useCallback(() => {
    setHighlightedFeat({ featId });
  }, [ setHighlightedFeat, featId ]);

  const classes = useStyles();

  const accordionSummaryClass = dataOnWeb && finished ? classes.accordionOnWebFinished
    : finished ? classes.accordionFinished
    : classes.accordionSummary;

  const onChange = useCallback(e => {
    const fieldName = e.target.name;
    const data = e.target.value;
    setForm(pf => ({
      ...pf,
      landscapeUnits: pf.landscapeUnits.map((r, currInd) => index === currInd ? ({ ...r, [fieldName]: data }) : r),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onCheckboxChange = useCallback(e => {
    setForm(pf => ({
      ...pf,
      landscapeUnits: pf.landscapeUnits.map((lu, currInd) => index === currInd ? ({ ...lu, [e.target.name]: e.target.checked }) : lu),
    }));
  // eslint-disable-next-line
  }, [ index ]);

  const onBlurNumber = useCallback(e => {
    setForm(pf => ({
      ...pf,
      landscapeUnits: pf.landscapeUnits.map((lu, currInd) =>
        index === currInd ? ({ ...lu, [e.target.name]: parseNumber(lu[e.target.name], 5) ?? null }) : lu,
      ),
    }));
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  const onChangeBigNumber = useCallback(e => {
    const data = e.target.value;
    if (!testDecimalsFormat(data, { decimals: 5, wholePart: 10 })) {
      return;
    }
    onChange(e);
  // eslint-disable-next-line -- como setForm viene de un useState, no necesita incluirse aquí
  }, [ index ]);

  return <Accordion TransitionProps={accordionTransitionPropObject}>
    <AccordionSummary className={ accordionSummaryClass } expandIcon={<ExpandMoreIcon />}>
      <Box display='flex' flexGrow={ 1 } alignItems='center' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          { Boolean(errors) &&
            <Box component="span" mr={1} color="error.main">
              ¡Problema al validar datos!
            </Box>
          }
          <Typography>
            Unidad de paisaje #{index + 1}{`: ${landscapeUnit.name || ''}`}
          </Typography>
        </Box>
        <Box>
          <Box onClick={stopEventPropagation} display="inline" mr={4}>
            <FormControlLabel labelPlacement="start" label="¿Finalizado?" className={classes.checkLabel}
              control={ <Checkbox color="primary" checked={finished}
                onChange={onCheckboxChange} name='finished'
              />
              }
            />
          </Box>
          <Box onClick={stopEventPropagation} display="inline">
            <Button variant="contained" color="secondary" onClick={ toggleHighlight }>
              Des/marcar en mapa
            </Button>
          </Box>
        </Box>
      </Box>
    </AccordionSummary>
    <AccordionDetails className={classes.accordionDetails}>
      <Grid container direction="row" spacing={2}>
        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-name`}>Nombre</TypographicInputLabel>
          <TextField value={landscapeUnit.name || ''} name="name" id={`${featId}-name`} variant="outlined"
            size="small" onChange={onChange} fullWidth autoComplete="off" />
        </Grid>

        <Grid item md={6} xs={6}>
          <TypographicInputLabel htmlFor={`${featId}-area-ha`}>Área [ha]</TypographicInputLabel>
          <TextField value={landscapeUnit.areaHa ?? ''} name="areaHa" id={`${featId}-area-ha`} variant="outlined" size="small"
            onBlur={onBlurNumber} fullWidth autoComplete="off"
            error={Boolean(errors?.areaHa)}
            onChange={onChangeBigNumber}
            helperText={<>
              <span>Área en ha, con hasta 5 decimales.</span>{landscapeUnit.areaHa !== null ?
                <span>Valor en m2: {landscapeUnit.areaHa * 10000}</span> : null}
            </>}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-biophysical-attributes`}>Calidad visual atributos biofisicos</TypographicInputLabel>
          <Autocomplete
            value={landscapeUnit.biophysicalAttributes}
            name={'biophysicalAttributes'}
            options={landscapeOptions.options}
            getOptionLabel={getLandscapeCategoryLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-biophysical-attributes`}
                variant="outlined"
                size="small"
                placeholder={'Bajo'}
                error={Boolean(errors?.biophysicalAttributes)}
                helperText={errors?.biophysicalAttributes?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'biophysicalAttributes', value: newValue === null ? null : newValue.value } });
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-aesthetic-attributes`}>Calidad visual atributo estéticos</TypographicInputLabel>
          <Autocomplete
            value={landscapeUnit.aestheticAttributes}
            name={'aestheticAttributes'}
            options={landscapeOptions.options}
            getOptionLabel={getLandscapeCategoryLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-aesthetic-attributes`}
                variant="outlined"
                size="small"
                placeholder={'Bajo'}
                error={Boolean(errors?.aestheticAttributes)}
                helperText={errors?.aestheticAttributes?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'aestheticAttributes', value: newValue === null ? null : newValue.value } });
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-structural-attributes`}>Calidad visual atributo estructurales</TypographicInputLabel>
          <Autocomplete
            value={landscapeUnit.structuralAttributes}
            name={'structuralAttributes'}
            options={landscapeOptions.options}
            getOptionLabel={getLandscapeCategoryLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-structural-attributes`}
                variant="outlined"
                size="small"
                placeholder={'Bajo'}
                error={Boolean(errors?.structuralAttributes)}
                helperText={errors?.structuralAttributes?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'structuralAttributes', value: newValue === null ? null : newValue.value } });
            }}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <TypographicInputLabel htmlFor={`${featId}-final-visual-quality`}>Resultado final calidad visual</TypographicInputLabel>
          <Autocomplete
            value={landscapeUnit.finalVisualQuality}
            name={'finalVisualQuality'}
            options={landscapeOptions.options}
            getOptionLabel={getLandscapeCategoryLabels}
            getOptionSelected={getOptionSelected}
            filterSelectedOptions
            renderInput={params => (
              <TextField
                {...params}
                id={`${featId}-final-visual-quality`}
                variant="outlined"
                size="small"
                placeholder={'Bajo'}
                error={Boolean(errors?.finalVisualQuality)}
                helperText={errors?.finalVisualQuality?.errorMessage}
              />
            )}
            onChange={(event, newValue) => {
              onChange({ target: { name: 'finalVisualQuality', value: newValue === null ? null : newValue.value } });
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box my={1}>
            <Box>
              <TypographicInputLabel htmlFor={`${featId}-particular-characteristics-`}>Características particulares</TypographicInputLabel>
              <TextField
                id={`${featId}-particular-characteristics`}
                className={classes.textArea}
                minRows={1}
                maxRows={3}
                multiline
                name="particularCharacteristics"
                onChange={onChange}
                helperText={`Característica particular de la unidad de paisaje solo si es que es declarado por el titular`}
                type="text"
                value={landscapeUnit.particularCharacteristics}
                variant="outlined"
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </AccordionDetails>
  </Accordion>;
});

LandscapeUnitForm.propTypes = {
  setHighlightedFeat: PropTypes.func,
  errors: PropTypes.object,
  index: PropTypes.number,
  setForm: PropTypes.func,
  landscapeUnit: PropTypes.object,
  landscapeOptions: PropTypes.object.isRequired,
};

LandscapeUnitForm.displayName = 'LandscapeUnitForm';


export { LandscapeUnitForm };