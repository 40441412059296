import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { red } from '@material-ui/core/colors';


const useStyles = makeStyles({
  button: ({ textColor, backgroundColor, hoverBgColor, disabledBgColor, disabledTextColor, stylesObj }) => ({
    ...(textColor ? { color: textColor } : {}),
    ...(backgroundColor ? { backgroundColor } : {}),
    ...(hoverBgColor ? { '&:hover': { backgroundColor: hoverBgColor } } : {}),
    ...(disabledBgColor || disabledBgColor ? { '&:disabled': {
      ...(disabledBgColor ? { backgroundColor: disabledBgColor } : {}), ...(disabledTextColor ? { color: disabledTextColor } : {}),
    } } : {}),
    ...(stylesObj ?? {}),
  }),
});

const ColorButton = (
  { textColor, backgroundColor, hoverBgColor, disabledBgColor, disabledTextColor, stylesObj, children, variant = 'contained',
    className, ...props },
) => {
  const classes = useStyles({ textColor, backgroundColor, hoverBgColor, disabledBgColor, disabledTextColor, stylesObj });
  return <Button variant={variant} className={clsx(classes.button, className)} {...props}>
    { children }
  </Button>;
};

ColorButton.propTypes = {
  textColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  hoverBgColor: PropTypes.string,
  disabledBgColor: PropTypes.string,
  disabledTextColor: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
  stylesObj: PropTypes.object,
  children: PropTypes.any,
};

const dangerColors = {
  contained: {
    textColor: 'white',
    backgroundColor: red[500],
    hoverBgColor: red[700],
  },
  text: {
    textColor: red[500],
    hoverBgColor: red[50],
  },
};

const DangerButton = ({ variant = 'contained', ...props }) => ColorButton({ ...dangerColors[variant], variant, ...props });


export {
  ColorButton,
  DangerButton,
};
