import yup from 'src/scenes/yup';


// TODO: confirmar obligatoriedad de todas las cosas
const landscapeUnitSchema = {
  name: yup.string().nullable(),
  particularCharacteristics: yup.string().nullable(),
  biophysicalAttributes: yup.string().required('Campo obligatorio'),
  aestheticAttributes: yup.string().required('Campo obligatorio'),
  structuralAttributes: yup.string().required('Campo obligatorio'),
  finalVisualQuality: yup.string().required('Campo obligatorio'),
  areaHa: yup.number().min(0).nullable(),
};

const viewshedSchema = {
  name: yup.string().nullable(),
  areaHa: yup.number().min(0).nullable(),
};

const observationPointSchema = {
  name: yup.string().nullable(),
  description: yup.string().nullable(),
  locality: yup.string().nullable(),
};

const landscapeSchema = yup.object({
  geoJson: yup.object()
    .when('badData', { is: true,
      then: schema => schema.nullable(),
      otherwise: schema => schema.required('Si no puedes dar geometrías, marca la casilla de datos erróneos o no disponibles'),
    }),
  landscapeUnits: yup.array().of(yup.lazy(lu => !lu.finished ? yup.object() : yup.object().shape(landscapeUnitSchema))),
  viewsheds: yup.array().of(yup.lazy(v => !v.finished ? yup.object() : yup.object().shape(viewshedSchema))),
  observationPoints: yup.array().of(yup.lazy(op => !op.finished ? yup.object() : yup.object().shape(observationPointSchema))),
  // TODO: decidir si validamos badData y comentarios? (por ej. que si badData entonces los comentarios no estén vacíos...)
  badData: yup.boolean(),
  comments: yup.string(),
});

export const arraySchemaIds = [ 'landscapeUnits', 'viewsheds', 'observationPoints' ];


export default landscapeSchema;